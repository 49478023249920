<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="componentManage"
    :get-list-func="getList"
    :queryParam="queryParam"
    can-select
    :show-export-excel-button="false"
    :show-import-excel-button="false">
    <div slot="searchBox">
      <componentManage-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <delete-componentManage-button :ids="selectedRows.map(v => v.id)" @xzdListPageReload="xzdListPageReload"/>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="groupName" :label="$t('component.group')"/>
      <el-table-column prop="name" :label="$t('component.name')"/>
      <el-table-column :label="$t('component.icon')">
        <template slot-scope="{row}">
          <div class="component-icon">
            <i v-if="(row.icon || '').indexOf('c-icon ') === 0" :class="row.icon"/>
            <img v-else :src="row.icon" :alt="$t('component.icon')"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sort" :label="$t('component.sort')"/>
      <el-table-column :label="$t('common.operate')" width="200" align="center">
        <template slot-scope="scope">
          <save-componentManage-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
          <delete-componentManage-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/componentManage";
import ComponentManageListSearchBox from "./ComponentManageListSearchBox";
import SaveComponentManageButton from "../buttons/SaveComponentManageButton";
import DeleteComponentManageButton from "../buttons/DeleteComponentManageButton";

export default {
  name: "ComponentManageList",
  components: {DeleteComponentManageButton, SaveComponentManageButton, ComponentManageListSearchBox},
  data() {
    return {
      queryParam: {},
      getList,
    }
  },
  
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    }
  }
}
</script>
<style scoped lang="scss">
.component-icon {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .c-icon {
    font-size: 25px;
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>