export default [
  "bianji--",
  "shanchu--",
  "lianjie--",
  "geshishua",
  "bangding",
  "gongyong--bianji",
  "buzhoutiao",
  "biaodan",
  "biaoqianye",
  "biaoge",
  "anniu",
  "danxuankuang",
  "biaoqian",
  "duoxuankuang",
  "chouti",
  "jinggaotishi",
  "guding",
  "fenye",
  "huibiaoshuzi",
  "jieguo",
  "gujiaping",
  "daohangcaidan",
  "chuansuokuang",
  "duihuakuang",
  "jilianxuanze",
  "pingfen1",
  "qipaokapian",
  "pinglun",
  "quanjubuju",
  "shuxingkongjian",
  "youzuobuju",
  "jindutiao",
  "kaiguan",
  "riqixuanzekuang",
  "wenzitishi",
  "shijianxuanzekuang",
  "kapian",
  "liebiao",
  "tiji1",
  "tongjishuzhi",
  "shuxuanze",
  "shangchuan",
  "zuoyoubuju",
  "quanjutishi",
  "tongzhitixingkuang",
  "mianbaoxie",
  "xialacaidan",
  "zoumadeng",
  "kongzhuangtai1",
  "tubiao",
  "zhediemianban",
  "zuozhongyoubuju",
  "yetou",
  "xuanzeqi",
  "rili",
  "shuzishurukuang",
  "shijianzhou",
  "touxiang",
  "zidongwancheng",
  "jiazaizhong",
  "huadongshurutiao",
  "qipaoquerenkuang",
  "miaoshuliebiao",
  "shurukuang",
  "zhuti",
  "zizhuti",
  "baguatu",
  "jinzhimiaodian",
  "zhadao",
  "bodongkaiguan",
  "shezhi",
  "a-3d",
  "shanchu",
  "chaijie",
  "yiyang",
  "xuanzekuang",
  "jinyong",
  "wufayidong",
  "suolvetu1",
  "zhexian",
  "suolvetu",
  "ditu",
  "fangdajing",
  "yibiaopanquanpan",
  "yibiaopanbanpan",
  "kongzhianniu",
  "chilunxiang",
  "yuanhu",
  "tuxingzhishideng1",
  "zhixian1",
  "yeyabeng1",
  "chuizhizhixian",
  "biaochi",
  "shuipingzhixian",
  "yuyan",
  "maodian",
  "lock",
  "unlock",
  "line",
  "share",
  "maodianjiyong",
  "attention",
  "qianbi",
  "to-triangleSolid",
  "shequ",
  "curve",
  "reply",
  "save",
  "folder",
  "help-circle",
  "project",
  "duixiangmoxing",
  "moxing",
  "yewu",
  "renyuan",
  "dakai",
  "quanfengbi",
  "tool",
  "rounddelete",
  "roundadd",
  "wenduji",
  "shuiliujianceqi",
  "tiaojianshijian",
  "yihuomen",
  "ORmen",
  "jibenshijian",
  "jinzhimen",
  "kaiguanshijian",
  "youxianANDmen",
  "weizhankaishijian",
  "zhuanyifuhao",
  "shijian",
  "biaojuemen",
  "ANDmen",
  "huanxing",
  "kongzhuangtai",
  "pingfen",
  "tiji",
  "04",
  "02",
  "03",
  "06",
  "05",
  "08",
  "01",
  "07",
  "09",
  "10",
  "file-add",
  "image_upload",
  "image-add",
  "pen",
  "pencil",
  "miaodianjiyong",
  "pool",
  "progress",
  "appreciate",
  "check",
  "close",
  "roundcheckfill",
  "roundcheck",
  "roundclosefill",
  "roundclose",
  "warnfill",
  "warn",
  "commentfill",
  "likefill",
  "like",
  "notificationfill",
  "notification",
  "more",
  "moreandroid",
  "cart",
  "home",
  "message",
  "add",
  "appreciatefill",
  "infofill",
  "info",
  "sort",
  "copy",
  "noticefill",
  "notice",
  "font",
  "app",
  "angle-left",
  "angle-top",
  "angle-down",
  "angle-right",
  "clock",
  "arrow-down",
  "huaxue",
  "bold",
  "search",
  "tag",
  "image",
  "align-top",
  "bk-color",
  "help",
  "circle",
  "bar-chart",
  "play",
  "loading",
  "kefu",
  "cube",
  "star-half-o",
  "star-o",
  "star",
  "redo",
  "triangle-down",
  "triangle-up",
  "flow-event",
  "triangle-right",
  "triangle-left",
  "pc",
  "line-chart",
  "download",
  "user",
  "pentagram",
  "grid",
  "text",
  "file",
  "inital",
  "round-rect",
  "zip",
  "upload",
  "fly",
  "dashboard-chart",
  "picture",
  "pie-chart",
  "reduce",
  "align-center",
  "panel",
  "paobu",
  "yangguang",
  "arrow-right",
  "full-screen",
  "data",
  "folder-open",
  "json",
  "dash-dot",
  "tiled",
  "final",
  "box-add",
  "loop",
  "horizontal-between",
  "vertical-between",
  "arrow-left",
  "flow-start",
  "flow-document",
  "flow-data",
  "flow-ready",
  "rectangle",
  "diamond",
  "flow-manually",
  "flow-display",
  "flow-parallel",
  "flow-comment",
  "flow-queue",
  "rect",
  "triangle",
  "pentagon",
  "hexagon",
  "cloud",
  "msg",
  "flow-subprocess",
  "db",
  "internal-storage",
  "extern-storage",
  "pause",
  "action",
  "swimlane-v",
  "swimlane-h",
  "fork-v",
  "fork",
  "lifeline",
  "people",
  "focus",
  "simple-class",
  "class",
  "polyline",
  "svg",
  "to-triangle",
  "from-triangle",
  "from-diamond",
  "to-diamond",
  "to-circle",
  "from-circle",
  "to-lineUp",
  "from-lineDown",
  "to-lineDown",
  "from-lineUp",
  "from-",
  "to-",
  "from-triangleSolid",
  "from-diamondSolid",
  "to-diamondSolid",
  "to-circleSolid",
  "from-circleSolid",
  "from-line",
  "to-line",
  "arrow-up",
  "align-bottom",
  "align-left",
  "align-right",
  "align-middle",
  "mind",
  "curve2",
  "twoway-arrow",
]