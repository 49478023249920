<template>
  <span style="text-align: center">
    <el-button size="mini" type="primary" @click="$refs.dialog.start()">{{id ? $t('button.edit') : $t('button.add')}}</el-button> &nbsp;
    <SaveComponentDialog :id="id" ref="dialog" @success="$emit('xzdListPageReload', $event)"/>
  </span>
</template>
<script>
import SaveComponentDialog from "@/views/componentManage/buttons/SaveComponentDialog";

export default {
  name: "SaveComponentManageBtn",
  components: {SaveComponentDialog},
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>